import CryptoJS from 'crypto-js';

const utilEncrypt = {
  // 렌더링에서 쓰면안됨
  encryptRsa: async (message: string) => {
    const { JSEncrypt } = await import('jsencrypt');
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(process.env.NEXT_PUBLIC_RSA_PUBLIC_KEY);
    return jsEncrypt.encrypt(message) || '';
  },

  encodeBase64: (message: string, url?: boolean) => {
    const strategy = url ? CryptoJS.enc.Base64url : CryptoJS.enc.Base64;
    return strategy.stringify(CryptoJS.enc.Utf8.parse(message));
  },

  decodeBase64: (encoded: string, url?: boolean) => {
    const strategy = url ? CryptoJS.enc.Base64url : CryptoJS.enc.Base64;
    return strategy.parse(encoded).toString(CryptoJS.enc.Utf8);
  },

  encryptAes256: (message: string, key: string, url = false) => {
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Hex.parse('0000000000000000');
    const cipherText = CryptoJS.AES.encrypt(message, keyBytes, {
      iv,
    }).ciphertext;
    const strategy = url ? CryptoJS.enc.Base64url : CryptoJS.enc.Base64;
    return cipherText.toString(strategy);
  },

  decryptAes256: (encrypted: string, key: string, url = false) => {
    encrypted = url
      ? encrypted.replaceAll('-', '+').replaceAll('_', '/')
      : encrypted;
    const keyBytes = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Hex.parse('0000000000000000');
    const messageBytes = CryptoJS.AES.decrypt(encrypted, keyBytes, { iv });
    return messageBytes.toString(CryptoJS.enc.Utf8);
  },

  decryptToJSON: (data: string) =>
    JSON.parse(
      CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_AES_KEY),
        { iv: CryptoJS.enc.Hex.parse('0000000000000000') },
      ).toString(CryptoJS.enc.Utf8),
    ),

  encryptFromJSON: (data: any) =>
    CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_AES_KEY),
      { iv: CryptoJS.enc.Hex.parse('0000000000000000') },
    ).toString(),
};

export default utilEncrypt;

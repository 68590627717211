import Cookies from 'js-cookie';

import type { Token } from './types';

const accessTokenKey = 'accessToken';

const refreshTokenKey = 'refreshToken';

export const store = Cookies.withAttributes({
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
});

export default {
  get(): Token {
    return {
      accessToken: store.get(accessTokenKey),
      refreshToken: store.get(refreshTokenKey),
    };
  },
  set(tokens: Required<Token>): void {
    store.set(accessTokenKey, tokens.accessToken);
    store.set(refreshTokenKey, tokens.refreshToken);
  },
  clear(): void {
    store.remove(accessTokenKey);
    store.remove(refreshTokenKey);
  },
} as const;

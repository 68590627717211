import _ from 'lodash-es';
import { useSyncExternalStore } from 'react';

const scrollPositionStore = {
  value: { x: 0, y: 0 },
  subscribe: (callback: () => void) => {
    window.addEventListener('scroll', callback);
    return () => {
      window.removeEventListener('scroll', callback);
    };
  },
  getSnapshot: () => {
    const { scrollX, scrollY } = window;
    const newValue = { x: scrollX, y: scrollY };
    if (!_.isEqual(scrollPositionStore.value, newValue)) {
      scrollPositionStore.value = newValue;
    }
    return scrollPositionStore.value;
  },
};

export default function useScrollPosition() {
  return useSyncExternalStore(
    scrollPositionStore.subscribe,
    scrollPositionStore.getSnapshot,
    () => scrollPositionStore.value,
  );
}

import type { ImageLoader, ImageProps } from 'next/image';
import Image from 'next/image';

const loader: ImageLoader = ({ src, width }) => `${src}?w=${width}`;

const AltImage = (props: Omit<ImageProps, 'loader'>) => {
  return <Image {...props} loader={loader} alt={props.alt} />;
};

export default AltImage;

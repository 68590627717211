import utilEncrypt from '@landing/src/common/utilEncrypt';

export default {
  clear(): void {
    localStorage.removeItem('account');
  },
  get(): { email: string; password: string } | undefined {
    const encryptedAccountData = localStorage.getItem('account');
    if (!encryptedAccountData) {
      return undefined;
    }
    try {
      return JSON.parse(
        utilEncrypt.decryptAes256(
          encryptedAccountData,
          process.env.NEXT_PUBLIC_AES_KEY,
        ),
      );
    } catch {
      this.clear();
      return undefined;
    }
  },
  set(args: { email: string; password: string }): void {
    localStorage.setItem(
      'account',
      utilEncrypt.encryptAes256(
        JSON.stringify(args),
        process.env.NEXT_PUBLIC_AES_KEY,
      ),
    );
  },
} as const;

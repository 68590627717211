import { css } from '@emotion/react';
import styled from '@emotion/styled';
import AltImage from '@landing/src/common/AltImage';
import theme from '@landing/styles/theme';
import Link from 'next/link';
// import { useRouter } from 'next/router';

const PointLine = styled.div({
  width: 1.5,
  height: 12,
  background: '#bbb',
  margin: '8px',
  transform: 'rotate(30deg)',
  [theme.mobile]: {
    display: 'none',
  },
});

export default function Footer() {
  // 휴먼 스튜디오와 공유하는 페이지(로그인, 회원가입 등 헤더 컴포넌트 참조)가 있어 원래는 비활성화 했으나
  // 약관 페이지를 진입하는 곳이 푸터밖에 없기 때문에 홈에서만 보이도록 처리
  // const router = useRouter();
  // if (router.pathname !== '/') {
  //   return null;
  // }

  return (
    <footer
      css={css`
        position: relative;

        background: #4c4c4e;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 20px;
        padding: 40px 0;

        ${theme.mobile} {
          align-items: flex-start;
          flex-direction: column;
          padding: 40px;
        }

        color: #7b7c81;
        font-size: 13px;
        font-weight: 400;
      `}
    >
      <div
        css={css`
          margin-top: 36px;
          display: flex;
          flex-direction: column;
          align-items: center;
          ${theme.mobile} {
            align-items: flex-start;
          }
        `}
      >
        <AltImage
          alt="logo"
          src="/images/logo_white.png"
          width={186}
          height={18}
        />

        <div
          css={css`
            margin-top: 8px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <AltImage
            alt="logo_x"
            src="/images/logo_x.png"
            width={20}
            height={20}
          />
        </div>

        <div
          css={css`
            padding-right: 12px;
          `}
        >
          <AltImage
            alt="logo_humelo"
            src="/images/logo_humelo_white.png"
            width={90}
            height={24}
          />
        </div>
      </div>

      <div
        css={css`
          height: 150px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          ${theme.mobile} {
            height: auto;
          }
        `}
      >
        <PointLine />
        <p>(주)케이티 &emsp;|&emsp; 대표이사 김영섭</p>
        <p>경기도 성남시 분당구 불정로 90 (정자동)</p>
        <p>
          사업자등록번호 : 102-81-42945 &emsp;|&emsp; 통신판매업신고 :
          2001-경기성남-0048
        </p>
      </div>

      <div
        css={css`
          white-space: nowrap;
          display: flex;
          gap: 20px;
          height: 150px;
          flex-direction: column;
          ${theme.mobile} {
            width: 100%;
            height: auto;
            border-top: 1px solid #ddd;
            padding-top: 20px;
            flex-direction: row;
            align-items: center;
          }
        `}
      >
        <PointLine />
        <Link href="/policy?tab=privacy_management" passHref>
          <strong>개인정보처리방침</strong>
        </Link>
        <Link href="/policy?tab=terms_of_service">이용약관</Link>
        <Link href="/policy?tab=operation_policy">운영정책</Link>
      </div>

      <div
        css={css`
          white-space: nowrap;
          display: flex;
          gap: 20px;
          height: 150px;
          flex-direction: column;
          ${theme.mobile} {
            width: 100%;
            height: auto;
            border-top: 1px solid #ddd;
            padding-top: 20px;
            flex-direction: row;
            align-items: center;
          }
        `}
      >
        <PointLine />
        <Link href="/support/faq">자주 하는 질문</Link>
        {/* <Link href="/">오픈소스 라이센스</Link> */}
      </div>
    </footer>
  );
}

type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
};

export default function MypageIcon(props: Props) {
  const { width, height, stroke = '#454B66' } = props;
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="10" r="4" stroke={stroke} strokeWidth="1.5" />
        <path
          d="M8 23C8 19.134 11.134 16 15 16C18.866 16 22 19.134 22 23"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

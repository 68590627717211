import autoLogin from '@landing/src/utils/auto-login';
import token from '@landing/src/utils/token';
import axios, { AxiosError } from 'axios';
import CryptoJS from 'crypto-js';

import type { Member } from '../types';

const keyForValidationCode = CryptoJS.enc.Utf8.parse(
  process.env.NEXT_PUBLIC_AES_VALIDATION_CODE_KEY,
);

const keyForParsingData = CryptoJS.enc.Utf8.parse(
  process.env.NEXT_PUBLIC_AES_KEY,
);

const iv = CryptoJS.enc.Hex.parse('0000000000000000');

const _client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: { 'Service-Code': '001WEB001' },
});

const clearAuthData = () => {
  autoLogin.clear();
  token.clear();
};

let reissuePromise: Promise<unknown> | null = null;

export const tryAutoLogin = async () => {
  const accountData = autoLogin.get();
  if (!accountData) {
    clearAuthData();
    return;
  }
  const { email, password } = accountData;
  // eslint-disable-next-line import/no-cycle
  return import('../utils')
    .then((m) => m.default.encryptRawPassword(password))
    .then((password) =>
      _client.post('/token/authenticate', { email, password }),
    )
    .catch(() => clearAuthData());
};

const reissue = async () => {
  if (reissuePromise) {
    return reissuePromise;
  }
  const { refreshToken } = token.get();
  if (refreshToken) {
    reissuePromise = _client
      .post('/token/reissue', { refresh_token: refreshToken })
      .catch(tryAutoLogin)
      .finally(() => {
        reissuePromise = null;
      });
  } else {
    reissuePromise = tryAutoLogin().finally(() => {
      reissuePromise = null;
    });
  }
  return reissuePromise;
};

_client.interceptors.request.use(async (config) => {
  if (!config.url) {
    return config;
  }
  if (typeof window !== 'undefined' && !(config.data instanceof FormData)) {
    config.headers.setContentType('application/json');
  }
  const { accessToken } = token.get();
  if (accessToken) {
    const authorization = `Bearer ${accessToken}`;
    config.headers.setAuthorization(authorization);
    if (config.params) {
      Object.assign(config.params, { Authorization: authorization });
    }
  }
  return config;
});

_client.interceptors.response.use(
  (res) => {
    if (
      res.data &&
      typeof res.data === 'object' &&
      'data' in res.data &&
      typeof res.data.data === 'string' &&
      res.data.data
    ) {
      try {
        const decrypted = CryptoJS.AES.decrypt(
          res.data.data,
          keyForParsingData,
          { iv },
        ).toString(CryptoJS.enc.Utf8);
        res.data.data = JSON.parse(decrypted);
      } catch {
        /* empty */
      }
    }

    // 자동 토큰 설정
    const body = res.data as unknown;
    if (
      body &&
      typeof body === 'object' &&
      'data' in body &&
      body.data &&
      typeof body.data === 'object' &&
      'access_token' in body.data &&
      'refresh_token' in body.data &&
      typeof body.data.access_token === 'string' &&
      typeof body.data.refresh_token === 'string'
    ) {
      const { access_token, refresh_token } = body.data;
      token.set({
        accessToken: access_token,
        refreshToken: refresh_token,
      });
    }

    // 자동 토큰 삭제
    // SWR로 아래 API호출 시에는 반드시 키를 '/member'로 설정해야
    // swr 캐시를 삭제하는 effect가 실행됨 (repository/hooks/member/index.ts 참고)
    const { method, url } = res.config;
    if (
      (method === 'delete' && url === '/token/sign-out') || // 로그아웃
      (method === 'delete' && url === '/member') // 회원 탈퇴
    ) {
      clearAuthData();
    }
    return res;
  },
  async (e) => {
    // 네트워크 에러가 아닌 경우 처리하지 않음
    if (!(e instanceof AxiosError)) {
      throw e;
    }
    const { config, response } = e;
    // API 요청이 아닌 경우 처리하지 않음
    if (!config || !response || !config.url) {
      throw e;
    }
    const { status } = response;
    const { method, url } = config;
    const { refreshToken } = token.get();
    // 중복 로그인
    if (status === 432) {
      if (refreshToken) {
        // eslint-disable-next-line no-alert
        alert(
          // eslint-disable-next-line import/no-cycle
          await import('../utils').then((m) => m.default.getErrorMessage(e)),
        );
      }
      clearAuthData();
      throw e;
    }
    // 서버 세션이 초기화 된 경우 (외부에서 로그아웃 API 호출 또는 세션 만료)
    if (status === 433) {
      clearAuthData();
      throw e;
    }
    // 재발급 에러 시 자동로그인 시도를 하기 위해 throw
    if (method === 'post' && url === '/token/reissue') {
      throw e;
    }
    // Validation-Code 헤더 삽입
    // GET /member는 Validation-Code가 불필요한 API 이므로 무한 루프에 빠지지 않도록 제외
    if (
      refreshToken &&
      !config.headers.get('Validation-Code') &&
      !(method === 'get' && url === '/member')
    ) {
      const { email } = await _client
        .get<{ data: Member }>('/member')
        .then(({ data: { data } }) => data);
      const validationCode = CryptoJS.AES.encrypt(email, keyForValidationCode, {
        iv,
      }).toString();
      _client.defaults.headers['Validation-Code'] = validationCode;
      config.headers.set('Validation-Code', validationCode);
      return _client.request(config);
    }
    // 토큰 재발급 후 재요청
    if (status === 401 && (await reissue())) {
      return _client.request(config);
    }
    throw e;
  },
);

export default _client;

import client, { tryAutoLogin } from '@landing/src/repository/client';
import type { Member } from '@landing/src/repository/types';
import { utils } from '@landing/src/utils';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import useSWR, { useSWRConfig } from 'swr';

const isUnauthorizedError = (error: unknown): error is AxiosError =>
  error instanceof AxiosError &&
  (error.response?.status === 401 ||
    error.response?.status === 432 ||
    error.response?.status === 433);

// 토큰은 JWT이지만 서버에서는 세션 방식으로 구현되어 있어 토큰 유무만으로는 로그인 상태를 판단할 수 없으므로 주의.
// 로그인 상태를 판단하려면 서버에 GET /member 요청을 보내 토큰 유효성을 검사해야 함.
export const useMember = () => {
  const { mutate } = useSWRConfig();

  const memberSWR = useSWR(
    '/member',
    async (path) => {
      let { refreshToken } = utils.token.get();
      const autoLogin = utils.autoLogin.get();

      if (autoLogin && !refreshToken) {
        await tryAutoLogin();
        const { refreshToken: newRefreshToken } = utils.token.get();
        refreshToken = newRefreshToken;
      }

      if (!refreshToken) return Promise.reject(new Error('no token'));

      const res = await client.get<{ data: Member }>(path);
      return res;
    },
    { shouldRetryOnError: (error) => !isUnauthorizedError(error) },
  );

  // swr 캐시 초기화 & revalidate
  useEffect(() => {
    const { data, error } = memberSWR;
    // data와 error가 동시에 존재하는지 체크하고 초기화해야함
    // 아니면 revalidation 때문에 무한 루프에 빠짐.
    if (data && error) {
      mutate(
        () => true, // 모든 키를 대상으로
        undefined, // data를 undefined로 초기화
      );
    }
  }, [memberSWR, mutate]);

  return memberSWR;
};

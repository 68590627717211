import client from './client';
import constants from './constants';
import hooks from './hooks';
import utils from './utils';

export const repository = {
  client,
  constants,
  hooks,
  utils,
} as const;

const audioContainer = new Map<string, HTMLAudioElement>();

export default {
  play(args: {
    id: string;
    src: string;
    onStop: VoidFunction;
    onPlay: VoidFunction;
  }): void {
    const audio = audioContainer.get(args.id);
    if (audio && audio.src === args.src) {
      return;
    }
    this.stop({ id: args.id });
    const newAudio = new Audio(args.src);
    new Promise<void>((r) => {
      newAudio.onerror = () => r();
      newAudio.onended = () => r();
      newAudio.onpause = () => r();
    }).then(() => args.onStop());
    newAudio.play().then(() => args.onPlay());
    audioContainer.set(args.id, newAudio);
  },

  stop(args: { id: string }): void {
    const audio = audioContainer.get(args.id);
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  },

  stopAll(): void {
    audioContainer.forEach((audio) => {
      audio.pause();
      audio.currentTime = 0;
    });
  },
} as const;

interface Menu {
  readonly id: string;
  readonly name: string;
  readonly subMenu: ReadonlyArray<{
    readonly icon: string;
    readonly name: string;
    readonly link: string;
  }>;
}

export const MENULIST: ReadonlyArray<Menu> = [
  {
    id: 'guide',
    name: '활용 방법',
    subMenu: [],
  },
  {
    id: 'plan',
    name: '요금',
    subMenu: [
      {
        icon: '/icons/ic_plan_bill.png',
        name: '요금 안내',
        link: '/plan/planinfo',
      },
      {
        icon: '/icons/ic_plan_charge.png',
        name: '글자 충전',
        link: '/plan/charge',
      },
      // {
      //   icon: '/icons/ic_my_ai_voice.png',
      //   name: '마이 AI 보이스',
      //   link: '/plan/customvoice',
      // },
    ],
  },
  {
    id: 'support',
    name: '고객지원',
    subMenu: [
      {
        icon: '/icons/ic_support_1.png',
        name: '공지사항',
        link: '/support/notice',
      },
      {
        icon: '/icons/ic_support_2.png',
        name: '자주 하는 질문',
        link: '/support/faq',
      },
      {
        icon: '/icons/ic_support_3.png',
        name: '문의하기',
        link: '/support/inquiry',
      },
      // {
      //   icon: '/icons/ic_support_4.png',
      //   name: 'API 사용하기',
      //   link: '/support/api',
      // },
      {
        icon: '/icons/ic_support_5.png',
        name: '이벤트',
        link: '/support/event',
      },
      {
        icon: '/icons/ic_support_6.png',
        name: '파트너',
        link: '/support/partner',
      },
    ],
  },
];

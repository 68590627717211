import DefaultHeader from './Default';
import MobileHeader from './Mobile';

export default function Header() {
  return (
    <>
      <DefaultHeader />
      <MobileHeader />
    </>
  );
}

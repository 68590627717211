import AltImage from '@landing/src/common/AltImage';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const Component = () => {
  const router = useRouter();
  const isHome = router.pathname === '/';
  const src = isHome ? '/icons/ic_mobile_w.png' : '/icons/ic_mobile.png';
  return <AltImage alt="" src={src} width={16} height={16} />;
};

export const MobileIcon = dynamic(
  async () => {
    const UAParser = await import('ua-parser-js').then((m) => m.default);
    const { device } = UAParser();
    if (device.type !== 'mobile') {
      return () => null;
    }
    return Component;
  },
  { ssr: false },
);

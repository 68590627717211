type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
};

export default function LoginIcon(props: Props) {
  const { width, height, stroke = '#454B66' } = props;
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 10V8C11 7.44772 11.4477 7 12 7H21C21.5523 7 22 7.44772 22 8V22C22 22.5523 21.5523 23 21 23H12C11.4477 23 11 22.5523 11 22V20"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="5.75"
          y1="15"
          x2="16.25"
          y2="15"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M15 12L18 15L15 18"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

import { css } from '@emotion/react';
import theme from '@landing/styles/theme';
import type { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';

interface Props {
  readonly children: ReactNode;
  readonly className?: string;
}

export default function MainLayout(props: Props) {
  return (
    <>
      <Header />

      <main
        className={props.className}
        css={css`
          min-height: calc(100vh - 200px);
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 40px;
          padding-top: 120px;
          padding-bottom: 60px;
          ${theme.tablet} {
            padding: 40px 20px;
          }
        `}
      >
        {props.children}
      </main>

      <Footer />
    </>
  );
}

import audio from './audio';
import autoLogin from './auto-login';
import hooks from './hooks';
import token from './token';

export const utils = {
  audio,
  autoLogin,
  hooks,
  token,
} as const;

type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
};

export default function LogoutIcon(props: Props) {
  const { width, height, stroke = '#454B66' } = props;
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="9" r="4" stroke={stroke} strokeWidth="1.5" />
        <path
          d="M8.28988 22C8.10128 21.3663 8 20.695 8 20C8 16.134 11.134 13 15 13C16.635 13 18.1391 13.5606 19.3306 14.5"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M18 19.8696H21M24 19.8696H21M21 19.8696V17M21 19.8696V23"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

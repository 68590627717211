const size = {
  small: '600px',
  mobile: '992px',
  tablet: '1200px',
  medium: '1350px',
  laptop: '1440px',
  gaparea: '1441px',
  desktop: '1441px',
  large: '1600px',
  xlarge: '1700px',
};

const theme = {
  small: `@media(max-width: ${size.small})`,
  mobile: `@media(max-width: ${size.mobile})`,
  tablet: `@media(max-width: ${size.tablet})`,
  medium: `@media(max-width: ${size.medium})`,
  laptop: `@media(max-width: ${size.laptop})`,
  desktop: `@media(min-width: ${size.desktop})`,
  gaparea: `@media(min-width: ${size.gaparea}) and (max-width: ${size.desktop})`,
  large: `@media(max-width: ${size.large})`,
  xlarge: `@media(max-width: ${size.xlarge})`,
};

export default theme;

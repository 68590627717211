import { css } from '@emotion/react';
import AltImage from '@landing/src/common/AltImage';
import { repository } from '@landing/src/repository';
import LoginIcon from '@landing/src/svg/LoginIcon';
import LogoutIcon from '@landing/src/svg/LogoutIcon';
import MypageIcon from '@landing/src/svg/MypageIcon';
import SignupIcon from '@landing/src/svg/SignupIcon';
import token from '@landing/src/utils/token';
import theme from '@landing/styles/theme';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { MENULIST } from '../constants';
import { MobileIcon } from '../MobileIcon';

export default function MobileHeader() {
  const router = useRouter();
  const [onHover, setOnHover] = useState('');

  const isHome = router.pathname === '/';
  const backgroundColor = isHome ? '#171717' : 'white';
  const color = isHome ? 'white' : '#454B66';
  const logoImage = isHome ? 'logo_white.png' : 'logo_color.png';

  const { accessToken, refreshToken } = token.get();

  // 휴먼 스튜디오 로고 이미지
  const humanStudioLogoImage = isHome
    ? 'human_studio_logo_white.png'
    : 'human_studio_logo_dark_gray.png';
  // 휴먼 스튜디오와 공통으로 사용하는 페이지에서는 메뉴버튼 숨김
  const hiddenPaths = [
    '/login',
    '/sign-up',
    '/sign-up/complete',
    '/mypage/editInfo',
    '/mypage/editpassword',
  ];
  const hideMenu = hiddenPaths.includes(router.pathname);

  const humanStudioURL = new URL(process.env.NEXT_PUBLIC_HUMANSTUDIO_URL);
  if (accessToken && refreshToken) {
    humanStudioURL.searchParams.set('access_token', accessToken);
    humanStudioURL.searchParams.set('refresh_token', refreshToken);
  }

  const { data: memberResponse } = repository.hooks.useMember();

  const { data: member } = memberResponse?.data ?? {};

  const signout = repository.hooks.useSignOut();

  return (
    <header
      css={css`
        position: sticky;
        z-index: 9999;
        top: 0;
        background: ${backgroundColor};
        display: none;
        ${theme.tablet} {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 70px;
          max-width: 1440px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          ${theme.tablet} {
            width: 90%;
          }
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <Link
            href="/"
            css={css`
              margin-right: ${hideMenu ? '10px' : '20px'};
            `}
          >
            <AltImage
              alt="KT AI STUDIO"
              src={`/images/${logoImage}`}
              width={hideMenu ? 110 : 134}
              height={hideMenu ? 12 : 13}
            />
          </Link>
          {hideMenu && (
            <div>
              <span
                css={css`
                  color: ${color};
                  text-align: center;
                  margin-right: 10px;
                `}
              >
                x
              </span>
              <a
                href={
                  accessToken || refreshToken
                    ? `${process.env.NEXT_PUBLIC_HUMANSTUDIO_URL}?access_token=${accessToken}&refresh_token=${refreshToken}`
                    : process.env.NEXT_PUBLIC_HUMANSTUDIO_URL
                }
              >
                <AltImage
                  alt="KT AI HUMAN STUDIO"
                  src={`/images/${humanStudioLogoImage}`}
                  width={120}
                  height={12}
                />
              </a>
            </div>
          )}
        </div>
        <div
          css={css`
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            ${theme.tablet} {
              width: fit-content;
            }
            & > * {
              width: 32px;
              height: 32px;
              background: none;
            }
          `}
        >
          {member && (
            <>
              <Link href="/mypage">
                <MypageIcon width={32} height={32} stroke={color} />
              </Link>
              <button
                type="button"
                disabled={signout.isMutating}
                onClick={() => signout.trigger()}
              >
                <LogoutIcon width={32} height={32} stroke={color} />
              </button>
            </>
          )}
          {!member && (
            <>
              <Link href="/login">
                <LoginIcon width={32} height={32} stroke={color} />
              </Link>
              <Link href="/sign-up">
                <SignupIcon width={32} height={32} stroke={color} />
              </Link>
            </>
          )}
        </div>
      </div>

      {!hideMenu && (
        <nav
          css={css`
            width: 100%;
            height: 60px;
            background: ${backgroundColor};
            box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
            border-right: none;
            display: flex;
            justify-content: space-evenly;

            & > * {
              background: none;
              font-size: 16px;
              line-height: 100%;
              display: flex;
              align-items: center;
              position: relative;
            }
          `}
        >
          <Link
            href={member ? '/app' : '/login'}
            css={css`
              position: relative;
              color: ${color};
              font-size: 16px;
              font-weight: 500;
              white-space: nowrap;
              display: flex;
              align-items: center;
            `}
          >
            스튜디오
            <MobileIcon />
          </Link>

          {MENULIST.map(({ id, name, subMenu }) => {
            const hasSubMenu = subMenu.length > 0;
            const isSelected = router.asPath.startsWith(`/${id}`);
            const menuArrowSrc = isHome
              ? '/icons/ic_menu_arrow_w.png'
              : '/icons/ic_menu_arrow.png';
            if (hasSubMenu) {
              return (
                <button
                  key={id}
                  type="button"
                  onClick={() => setOnHover((it) => (it === '' ? id : ''))}
                >
                  <span
                    css={css`
                      font-weight: ${isSelected ? 600 : 500};
                      color: ${isSelected ? '#3a95ff' : color};
                    `}
                  >
                    {name}
                  </span>
                  <AltImage
                    alt=""
                    src={menuArrowSrc}
                    width={16}
                    height={16}
                    css={css`
                      position: absolute;
                      right: -4px;
                      transform: translateX(100%);
                    `}
                  />
                  <aside
                    css={css`
                      margin-top: 15px;
                      position: absolute;
                      top: 0;
                      left: 50%;
                      transform: translateX(-60%);
                      min-width: 200px;
                      border-radius: 10px;
                      padding-top: 25px;
                      display: ${onHover === id ? 'block' : 'none'};
                    `}
                  >
                    <nav
                      css={css`
                        box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.09);
                        background: white;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                        padding: 24px 30px;
                      `}
                    >
                      {subMenu.map((subMenu) => (
                        <Link
                          key={subMenu.link}
                          href={subMenu.link}
                          css={css`
                            display: flex;
                            align-items: center;
                            gap: 8px;
                          `}
                        >
                          <AltImage
                            alt={subMenu.name}
                            src={subMenu.icon}
                            width={24}
                            height={24}
                          />
                          <span>{subMenu.name}</span>
                        </Link>
                      ))}
                    </nav>
                  </aside>
                </button>
              );
            }
            return (
              <Link
                key={id}
                href={`/${id}`}
                css={css`
                  font-weight: ${isSelected ? 600 : 500};
                  color: ${isSelected ? '#3a95ff' : color};
                `}
              >
                {name}
              </Link>
            );
          })}

          <Link
            href={humanStudioURL.href}
            css={css`
              color: ${color};
              font-size: 16px;
              font-weight: 500;
              white-space: nowrap;
              display: flex;
              align-items: center;
            `}
          >
            Human Studio
          </Link>
        </nav>
      )}
    </header>
  );
}

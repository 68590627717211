import type { InquiryCategory } from './types';

export default {
  'display-names': {
    'notice-category': {
      service: '서비스 안내',
      system: '시스템 안내',
      update: '업데이트',
      etc: '기타',
    },
    plan: {
      ProMax: 'Super Plus',
      Pro: 'Super',
      Basic: 'Lite',
      Free: 'Free',
      Deferred: 'Deferred',
    },
    'inquiry-category': {
      SERVICE: '서비스 문의',
      ACCOUNT: '계정 문의',
      PAYMENT: '결제 문의',
      COMPLAIN: '불편사항 문의',
      OTHER: '기타 문의',
      AFFILIATE: '제휴 문의',
      API: 'API 사용문의',
      CREATE: '제작 문의',
    } satisfies Record<InquiryCategory, string>,
    'inquiry-status': {
      READY: '미답변',
      COMPLETE: '답변완료',
    },
  },
  'my-ai-voice': {
    price: {
      current: 99000,
      original: undefined as number | undefined,
    },
  },
} as const;
